import React from 'react';

export default () => {
  return (
    <div>
      <span className="tick">Thank you for your feedback!</span>
      <p>
        We are constantly working to improve your specialist experience, so all
        ideas and suggestions - positive or critical - are greatly appreciated.
      </p>
    </div>
  );
};
