import React from 'react';
import Header from './Header';
import Survey from './Survey';

export default () => {
  return (
    <div className="container">
      <Header />
      <Survey />
    </div>
  );
};
