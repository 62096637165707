import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from './Slider';
import Button from './Button';
import Store from '../Store';

export default class Feedback extends Component {
  constructor(props) {
    super(props);

    this.initialRating = 5;

    this.state = {
      selectedRating: this.initialRating,
      comment: '',
    };

    this.onRatingChange = this.onRatingChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCommentChange = this.onCommentChange.bind(this);
  }

  onRatingChange(value) {
    this.setState({ selectedRating: value });
  }

  onCommentChange(value) {
    this.setState({ comment: value });
  }

  onSubmit() {
    const { selectedRating, comment } = this.state;
    const store = new Store();

    store.store({
      rating: selectedRating,
      comment
    });

    const { onNextStep } = this.props;
    onNextStep();
  }

  render() {
    return (
      <div className="feedback">
        <p className="info">
          How likely are you to recommend being a Third Bridge specialist to a
          friend or colleague?
          <br />
          <span className="tooltip">
            (1 being not likely and 10 being extremely likely)
          </span>
        </p>

        <Slider
          initialValue={this.initialRating}
          min={1}
          max={10}
          step={1}
          onChange={this.onRatingChange}
          orientation="horizontal"
          disabled=""
        />

        <div className="comment-container">
          <hr />
          <p className="info">What is one thing we could do to improve your experience?</p>
          <textarea
            onChange={e => this.onCommentChange(e.target.value)}
            placeholder="What is one thing we could do to improve your experience?"
          />
          <Button onClick={this.onSubmit} value="Submit Survey" />
        </div>
      </div>
    );
  }
}

Feedback.propTypes = {
  onNextStep: PropTypes.func.isRequired
};
