import React from 'react';

import logo from '../images/logo.svg';

export default () => {
  return (
    <div className="header">
      <img src={logo} alt="Logo" />
      <span>Specialist Survey</span>
    </div>
  );
};
