import queryString from 'query-string';

/**
 * Returns a query string parameter
 *
 * @param {String} name
 * @returns {String}
 *
 * @throws Error If the query string parameter doesn't exist
 */
const getQueryStringParameter = name => {
  const queryStringParameters = queryString.parse(window.location.search);
  if (!queryStringParameters[name]) {
    throw new Error(`Missing parameter '${name}' from query string`);
  }

  return queryStringParameters[name];
};

export default getQueryStringParameter;
