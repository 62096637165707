import React from 'react';

export default props => {
  const { text } = props;
  return (
    <div>
      <p>{ text }</p>
    </div>
  );
};
