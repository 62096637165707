import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactBootstrapSlider from 'react-bootstrap-slider';
import 'bootstrap-slider/dist/css/bootstrap-slider.min.css';

export default class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentValue: props.initialValue
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const { value } = e.target;
    this.setState({ currentValue: value });
    const { onChange } = this.props;
    onChange(value);
  }

  render() {
    const { currentValue } = this.state;
    const { step, min, max, orientation, disabled } = this.props;
    return (
      <div className="slider-container">
        <ReactBootstrapSlider
          value={currentValue}
          change={this.onChange}
          step={step}
          min={min}
          max={max}
          orientation={orientation}
          tooltip="hide"
          disabled={disabled}
        />
        <span className="slider-min">{min}</span>
        <span className="slider-max">{max}</span>
        <div className="slider-value-container">
          <span className="slider-value-text">Value: </span>
          <span className="slider-current-value">{currentValue}</span>
        </div>
      </div>
    );
  }
}

Slider.propTypes = {
  initialValue: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  orientation: PropTypes.string.isRequired,
  disabled: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
