import React from 'react';
import PropTypes from 'prop-types';

export default function Button({ className, value, onClick }) {
  return (
    <input
      className={`btn ${className}`}
      type="button"
      value={value}
      onClick={onClick}
    />
  );
};

Button.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

Button.defaultProps =  {
    className: ''
};
