import React, { Component } from 'react';
import Feedback from './Feedback';
import ThankYou from './ThankYou';
import Message from './Message';
import Store from '../Store';
import getQueryStringParameter from '../utils';

export default class Survey extends Component {
  constructor(props) {
    super(props);

    this.steps = [];
    this.steps[1] = Feedback;
    this.steps[2] = ThankYou;

    this.state = {
      currentStep: 1,
      message: '',
      checksFinished: false
    };

    this.store = new Store();
    this.onNextStep = this.onNextStep.bind(this);
  }

  async componentDidMount() {
    try {
      // Check if there is data present in the query string
      // Show message if not
      const data = getQueryStringParameter('data');

      // Check if the token in data was already used
      // Show message if it was
      if (await this.store.isTokenUsed(data)) {
        this.setState({ message: 'Thank you! We have your feedback already.' });
      }
    } catch (e) {
      this.setState({
          message: 'Please follow the link provided in your email to leave your feedback. Thank you!'
        }
      );
    }

    this.setState({ checksFinished: true });
  }

  onNextStep() {
    this.setState(prevState => ({ currentStep: prevState.currentStep + 1 }));
  }

  render() {
    const { currentStep, checksFinished, message } = this.state;

    // Show loading animation until the checks are finished
    if (!checksFinished) {
      return (
        <div className="bubbles">
          <i className="bubble" />
          <i className="bubble" />
          <i className="bubble" />
        </div>
      );
    }

    if (message) {
      return (
        <div className="survey">
          <Message text={message}/>
        </div>
      );
    }

    const Step = this.steps[currentStep];

    return (
      <div className="survey">
        <h1>
          Let us know what
          <br/>
          you think.
        </h1>
        <Step onNextStep={this.onNextStep}/>
      </div>
    );
  }
}
