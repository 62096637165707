import { ApiClient, DefaultApi } from '@third-bridge/specialist-feedback-store-client';
import getQueryStringParameter from './utils';

export default class Store {
  constructor() {
    const storeApi = new ApiClient();
    storeApi.basePath = process.env.REACT_APP_PUBLIC_API_ENDPOINT;
    this.storeClient = new DefaultApi(storeApi);
  }

  /**
   * Checks if the token in data is already used
   *
   * @param {Object} data
   * @returns {Promise<boolean>}
   */
  async isTokenUsed(data) {
    const result = await this.storeClient.checkToken(data);

    return result.isTokenUsed;
  }

  /**
   * Sends feedback to Store API
   *
   * @param {Object} feedback
   */
  store(feedback) {
    try {
      const data = getQueryStringParameter('data');
      this.storeClient.store({ data, feedback });
    } catch (e) {
      // Do nothing
    }
  }
}
